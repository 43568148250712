/** 
  $orange: #f55f33
  $blue: #4F5BD5
  $dark-blue: #27405C
  $green: #42D5B0
  $yellow: #F9BA0F
  $pink: #FB1FFF
  $brown: #6B520D
*/

/**
* BOOTSTRAP EXTEND
*/
.font-blue-color {
  color: #001bd9;
}

.btn-tagether,
.btn-outline-tagether:hover,
.table-tagether th,
.table-tagether td {
  background-color: #001bd9;
  color: white;
}

.btn-outline-tagether,
.btn-tagether:hover {
  border-color: #001bd9;
  color: #001bd9;
}

.btn-blue,
.btn-outline-blue:hover,
.table-blue th,
.table-blue td,
.pagination-blue .active .page-link {
  background-color: #4f5bd5;
  color: white;
}

.btn-outline-blue,
.btn-blue:hover,
.pagination-blue .page-link {
  border-color: #4f5bd5;
  color: #4f5bd5;
}

.btn-dark-blue,
.btn-outline-dark-blue:hover,
.table-dark-blue th,
.table-dark-blue td,
.pagination-dark-blue .active .page-link {
  background-color: #27405c;
  color: white;
  text-align: center;
  font-size: 12px;
  vertical-align: center;
}

.btn-outline-dark-blue,
.btn-dark-blue:hover,
.pagination-dark-blue .page-link {
  border-color: #27405c;
  color: #27405c;
}

.bg-tagether-green {
  background-color: #42d5b0;
}

.bg-tagether-blue {
  background-color: #4f5bd5;
}

.bg-tagether-pink {
  background-color: #fb1fff;
}

.bg-tagether-yellow {
  background-color: #f9ba0f;
}

.bg-tagether-brown {
  background-color: #6b520d;
}

.border-tagether-green {
  border-color: #42d5b0;
}

.border-tagether-blue {
  border-color: #4f5bd5;
}

.border-tagether-pink {
  border-color: #fb1fff;
}

.border-tagether-yellow {
  border-color: #f9ba0f;
}

.text-tagether-yellow {
  color: #f9ba0f;
}

.nav-pills .nav-link.active {
  background-color: #fcfcfd;
  border: 1px rgba(0, 0, 0, 0.175) solid;
}

.border-tagether {
  border-color: #f55f33 !important;
}

.dropdown-toggle::after {
  display: none;
}

.bg-image {
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-image--instagram {
  background-image: url("./assets/images/instagram.svg");
}

/**
* CUSTOM
*/

.header-tabbar {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-left: 2rem;
  margin-right: 2rem
}

.hero-webapp-layout {
  display: flex;
  flex-direction: row;
}


.navigation-section {
  padding: 1 .5rem;
  width: 15%;
  margin: 1rem
}

.logout-button {
  width: 15%;
  background-color: #001bd9;
}

.content-section {
  padding: 3rem 2rem;
  width: 85%;
  background-color: #F7F7F7;
  height: auto !important;
  min-height: 100vh
}

@media screen and (max-width:425px) {
  .hero-webapp-layout {
    display: flex;
    flex-direction: column;
  }

  .navigation-section {
    width: 50%;
  }

  .logout-button {
    width: 50%;
  }

  .content-section {
    padding: 2rem;
    width: 100%;
  }
}

.font-blue-color {
  font-size: 28px;
  color: #001bd9;
  font-weight: bold;
}

.card-count-container {
  font-size: 22px;
  font-weight: bold;
}

.card-count-container {
  display: flex;
  flex-direction: row;
}

.card-count-box {
  padding: 1rem 2rem;
  border-radius: 4px;
  border-style: solid;
  border-width: .5px;
  border-color: darkgray;
  width: 40%
}

.card-count-box-space {
  margin-left: 3rem;
}

@media screen and (max-width:425px) {
  .font-blue-color {
    font-size: 18px;
    color: #001bd9;
    font-weight: bold;
  }

  .card-count-container {
    font-size: 12px;
    font-weight: bold;
  }

  .card-count-box {
    padding: 1rem;
  }
}

.card-body {
  padding: 1rem
}

.config-reward-card-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: .5rem 1rem;
}

.empty-reward-box-text {
  color: #FEFEFE;
  display: inline;
  text-align: center;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 15px;
  background-color: #F9BA0F;
}

.instagram-username-social-card {
  width: fit-content;
  padding: 2px 8px;
  border-radius: 20px;
  background-color: black;
  color: #F7F7F7;
  align-items: flex-end;
  margin-bottom: 1rem;
}

.instagram-username-social-card-text {
  text-align: end;
  justify-content: center;
  margin-bottom: 0;
  font-weight: bold;
}

.table-rounded {
  border-top-left-radius: 10px;
}

.table-rounded-bottom {
  border-top-right-radius: 10px;
}

.content-table {
  font-size: 12px;
}

.content-table-media {
  font-size: 10px;
}

.tutorial-temporary-message-box {
  position: relative;
  width: 50%;
  margin-top: 10%;
  margin-left: 25%;
  padding: 2rem;
  border-radius: 6px;
  background-color: #FEFEFE;
  display: flex;
  flex-direction: row;
}

.tutorial-temporary-message-title {
  width: fit-content;
  padding: 1.4rem;
  border-radius: 45px;
  color: #F7F7F7;
  align-items: center;
  margin-bottom: 1rem;
  margin-right: 2rem;
}

.logo {
  position: absolute;
  top: -48px;
}

@media (max-width: 767px) {
  .logo {
    position: initial;
  }
}

.background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: #1d2f43;
  z-index: -1;
}

.background--half {
  width: 66.66%;
}

@media (max-width: 991px) {
  .background--half {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .background {
    display: none;
  }
}

.gradient {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.gradient--bottom-left {
  bottom: 0;
  left: 0;
  background: url("./assets/images/layout/gradient-1.png") no-repeat bottom -150px left -350px;
  background-size: 593px;
}

.gradient--top-right {
  top: 0;
  right: 0;
  background: url("./assets/images/layout/gradient-2.png") no-repeat top -200px right -50px;
  background-size: 626px;
}

@media (max-width: 991px) {
  .gradient {
    display: none;
  }
}

.nav--sticky {
  position: sticky;
  top: 81px;
}

.nav-logout {
  position: fixed;
  bottom: 32px;
  width: 100%;
}

@media (max-width: 1399px) {
  .nav-logout {
    width: auto;
  }
}

@media (max-width: 1199px) {
  .nav-logout {
    width: auto;
  }
}

@media (max-width: 991px) {
  .nav-logout {
    position: initial;
    width: auto;
  }
}

.reward-icon {
  background-size: 20%;
  background-repeat: no-repeat;
  background-position: center;
}

.reward-icon-code {
  background-image: url("./assets/images/icons/icon-code.png");
}

.reward-icon-percent {
  background-image: url("./assets/images/icons/icon-percent.png");
}

.reward-icon-voucher {
  background-image: url("./assets/images/icons/icon-voucher.png");
}

.reward-icon-other {
  background-image: url("./assets/images/icons/icon-other.png");
}